import { useState } from "react";

import { settleCreditNotes } from "utils/api";

interface SettlementMutationPending {
  success: false;
  data: null;
  error: null;
}

interface SettlementMutationSuccess {
  success: true;
  data: { code: string };
  error: null;
}

type SettlementMutation = SettlementMutationPending | SettlementMutationSuccess;

const initialMutationValue = {
  success: false,
  data: null,
  error: null,
} as SettlementMutationPending;

interface MutationOptions {
  onChange: () => void;
}
export const useSettlementMutation = (
  invoiceItems: number[] | undefined,
  commercialGestureAmount: number | null,
  creditNoteId: number | undefined,
  notifyCustomer: boolean,
  { onChange }: MutationOptions
) => {
  const [settlementMutation, setSettlementMutation] = useState<SettlementMutation>(initialMutationValue);

  const mutate = async () => {
    const { data: generatedCoupon, status } = await settleCreditNotes({
      invoiceItems: invoiceItems,
      commercialGestureAmount,
      creditNoteId,
      notifyCustomer,
    });
    if (status === 201) {
      setSettlementMutation({
        success: true,
        data: generatedCoupon,
        error: null,
      });
      onChange();
    }
  };

  const reset = async () => {
    setSettlementMutation(initialMutationValue);
  };
  return { ...settlementMutation, mutate, reset };
};
