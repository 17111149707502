import React, { useState } from "react";

import { Button as AdminButton, Box, Checkbox, styled } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import Modal from "components/Modal";
import LazyLoader from "components/atoms/LazyLoader";
import { SmallBold } from "components/atoms/Typography";
import Body from "components/atoms/Typography/Body";
import BodyBold from "components/atoms/Typography/BodyBold";
import H3 from "components/atoms/Typography/H3";
import Small from "components/atoms/Typography/Small";

import { useCreditNotesToSettle } from "utils/hooks/useCreditNotesToSettle";
import { useInvoiceByCreditNoteId } from "utils/hooks/useInvoiceByCreditNoteId";
import { useSettlementMutation } from "utils/hooks/useSettlementMutation";

import ActionButton from "./ActionButton";
import VoucherItems from "./VoucherItems";

interface GenerateVoucherProps {
  orderId: number;
}

const StyledForm = styled("form")({
  width: "100%",
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));

const GenerateVoucher: React.FunctionComponent<GenerateVoucherProps> = ({ orderId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemsIds, setSelectedItemsIds] = useState<number[]>([]);
  const [form, setForm] = useState<{ value: number | null; error: string | null }>({
    value: null,
    error: null,
  });
  const [notifyCustomer, setNotifyCustomer] = useState(false);
  const isOneItemSelected = selectedItemsIds.length >= 1;

  const handleNotifyCustomer = () => {
    setNotifyCustomer((notifyCustomer) => !notifyCustomer);
  };

  const handleSetItemId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = parseInt(event.target.value);
    if (!selectedItemsIds.includes(itemId)) {
      setSelectedItemsIds([...selectedItemsIds, itemId]);
    } else {
      setSelectedItemsIds(selectedItemsIds.filter((id) => itemId !== id));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (!input) {
      setForm({ error: null, value: null });
      return;
    }
    const amount = parseInt(input);
    if (amount < 1) {
      setForm({
        value: amount,
        error: "Indiquez un montant supérieur à 0",
      });
      return;
    }

    setForm({ error: null, value: amount });
  };

  const { isSuccess, data: creditNotes, refetch } = useCreditNotesToSettle(orderId);
  const firstCreditNote = creditNotes && creditNotes.length > 0 ? creditNotes[0].id : undefined;
  const { isSuccess: isSuccessInvoice, data: invoice } = useInvoiceByCreditNoteId(
    creditNotes && creditNotes.length > 0 && creditNotes[0].id,
    isModalOpen,
    setSelectedItemsIds
  );

  const commercialGestureAmount = form.value ? form.value * 100 : null;

  const {
    success: settlementIsSuccesss,
    data: settlement,
    reset,
    mutate,
  } = useSettlementMutation(selectedItemsIds, commercialGestureAmount, firstCreditNote, notifyCustomer, {
    onChange: () => {
      refetch();
    },
  });

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isOneItemSelected) return;
    await mutate();
  };

  const handleCloseModal = () => {
    reset();
    setSelectedItemsIds([]);
    setIsModalOpen(false);
  };
  const getCurrentCouponAmount = () => {
    if (invoice && invoice.items.length > 0) {
      return (
        invoice.items
          .filter((item) => selectedItemsIds.includes(item.id))
          .reduce((acc, item) => acc + item.price_tax_included, 0) /
          100 +
        (form.value ? form.value : 0)
      );
    }
  };

  const currentCouponAmount = getCurrentCouponAmount();

  return (
    <>
      <ActionButton
        isSuccess={isSuccess}
        creditNotes={creditNotes}
        onClick={() => setIsModalOpen(true)}
        startIcon={<AddCircleIcon />}
      />

      <Modal
        handleCloseModal={handleCloseModal}
        modalClassName={`modal-small-content`}
        isOpen={isModalOpen}
        customStyle={{
          content: {
            padding: 32,
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            bottom: 0,
            width: "40%",
            height: "65%",
          },
        }}
      >
        <Box paddingTop={3} flexDirection="column" display="flex" width="100%">
          <Box justifyContent="center" display="flex">
            <H3 color="primary.main">Génération d'un bon d'achat</H3>
          </Box>
          <Box mt={5} justifyContent="center" display="flex">
            <SmallBold color="primary.main">Précisez les éléments pour lesquels générer un bon d'achat :</SmallBold>
          </Box>
          <Box mt={2} display="flex">
            {settlementIsSuccesss && settlement ? (
              <Box mt={3}>
                <Body>{`Génération du bon d'achat terminée. Code généré: ${settlement.code}`}</Body>
              </Box>
            ) : (
              <StyledForm onSubmit={submit}>
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                  {isSuccessInvoice && invoice ? (
                    <Box mt={2}>
                      <VoucherItems
                        handleChange={handleSetItemId}
                        isOneItemSelected={isOneItemSelected}
                        items={invoice.items.filter((item) => item.price_tax_included > 0)}
                        selectedItemsIds={selectedItemsIds}
                      />
                    </Box>
                  ) : (
                    <Box mt={2}>
                      <LazyLoader show={!isSuccessInvoice} />
                    </Box>
                  )}
                  <Box mt={3}>
                    <Box mb={1} justifyContent="center" display="flex">
                      <SmallBold color="primary.main">Ajouter un geste commercial :</SmallBold>
                    </Box>
                    <TextField
                      label="Précisez le montant"
                      variant="filled"
                      value={form.value}
                      error={!!form.error}
                      helperText={form.error}
                      onChange={handleChange}
                      type="number"
                      inputProps={{
                        min: "1",
                        step: "1",
                      }}
                    />
                  </Box>
                </Box>
                <Box mt={5} justifyContent="center" display="flex">
                  {currentCouponAmount ? (
                    <>
                      <Body component="span">
                        Confirmer la génération d'un bon d'achat d'un montant de&nbsp;
                        <BodyBold component="span" color="primary.main">
                          {currentCouponAmount.toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </BodyBold>
                      </Body>
                    </>
                  ) : null}
                </Box>
                <Box display="flex" width="100% !important" justifyContent="center" alignItems="center">
                  <StyledCheckbox checked={notifyCustomer} onChange={handleNotifyCustomer} />
                  <Small>Notifié le client par mail</Small>
                </Box>
                <Box marginTop={2} justifyContent="center" display="flex">
                  <AdminButton disabled={!!form.error} type="submit" variant="contained" color="secondary">
                    Confirmer
                  </AdminButton>
                </Box>
              </StyledForm>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GenerateVoucher;
