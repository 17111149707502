import { useQuery, useQueryClient } from "@tanstack/react-query";
import { get } from "lib/http";
import { Visit } from "types/visits";

export const useVisit = (visitId: number) => {
  const queryClient = useQueryClient();

  const getVisit = async () => {
    const url = `/murfy-erp/visit/${visitId}/`;
    const visit: Visit = await get(url);
    return visit;
  };

  const { data, isLoading, isError } = useQuery(["get-visit", visitId], getVisit);

  const deleteCache = () => {
    queryClient.removeQueries(["get-visit", visitId]);
  };

  return { data, isLoading, isError, deleteCache };
};
