import { useEffect, useState } from "react";

import { useQuery } from "react-admin";

import dataProvider from "dataProvider";

import { SUPPLIER_NAME_ASWO } from "constants/supplier";

import { sortSkus } from "utils/sku";

import { getHeaders } from "./headers";

export const useWorkshopFileTags = () => {
  const { data: workshopFileTags, loading: loadingWorkshopFileTags } = useQuery({
    type: "getList",
    resource: "workshop-file-tags",
    payload: {
      sort: { field: "text", order: "DESC" },
    },
  });
  return { workshopFileTags, loadingWorkshopFileTags };
};

export const useSkuSuppliers = () => {
  const { data: SupplierChoices } = useQuery({
    type: "getList",
    resource: "sku-suppliers",
    payload: {
      sort: { field: "id", order: "DESC" },
    },
  });
  return SupplierChoices;
};

export const useAswoSupplier = () => {
  const { data: aswoSupplier } = useQuery({
    type: "getOne",
    resource: "sku-suppliers",
    payload: {
      id: SUPPLIER_NAME_ASWO,
    },
  });
  return aswoSupplier;
};

export const getAswoSupplier = () => {
  return dataProvider.getOne("sku-suppliers", { id: SUPPLIER_NAME_ASWO });
};

export const useWorkshopFiles = (filter) => {
  const { data: workshopFiles } = useQuery({
    type: "getList",
    resource: "workshop-files",
    payload: {
      sort: { field: "id", order: "DESC" },
      filter: filter,
    },
  });
  return workshopFiles;
};

export const useWorkshopFile = (id) => {
  const { data: workshopFile } = useQuery({
    type: "getOne",
    resource: "workshop-files",
    payload: { id: id },
  });
  return workshopFile;
};

export const useWorkshopFilesCount = (filter) => {
  const { data: count } = useQuery({
    type: "getList",
    resource: "workshop-files-count",
    payload: { sort: { field: "id", order: "DESC" }, filter },
  });
  return count;
};

export const useProducts = (filter) => {
  const { data: products } = useQuery({
    type: "getList",
    resource: "products",
    payload: {
      sort: { field: "id", order: "DESC" },
      filter: filter,
    },
  });
  return products;
};

export const useProductModels = (filter) => {
  const { data: productModels } = useQuery({
    type: "getList",
    resource: "product-models",
    payload: {
      filter: filter,
      sort: {},
    },
  });
  return productModels;
};

export const getProductModel = (productModelId) => {
  return dataProvider.getOne("product-models", { id: productModelId }).then(({ data }) => data);
};

export const useProductTypes = (filter) => {
  const { data: productTypes } = useQuery({
    type: "getList",
    resource: "product-types",
    payload: {
      sort: { field: "id", order: "DESC" },
      filter: filter,
    },
  });
  return productTypes;
};

export const useProductTypeAttributes = (filter) => {
  const { data: productTypes } = useQuery({
    type: "getList",
    resource: "product-type-attributes",
    payload: {
      sort: { field: "id", order: "DESC" },
      filter: filter,
    },
  });
  return productTypes;
};

export const useProductBrands = () => {
  const { data: brands } = useQuery({
    type: "getList",
    resource: "product-brands",
    payload: {
      sort: {},
    },
  });
  return brands;
};

export const useDeliveryOptions = (postalCode, store) => {
  const { data: options } = useQuery({
    type: "getList",
    resource: "delivery-options",
    payload: {
      sort: {},
      postal_code: postalCode,
      filter: { store: store },
    },
  });
  return options;
};

export function useWarranties(price) {
  /* As Warranties do not change often, enable 15 minutes cache*/
  const { data: warranties } = useQuery({
    type: "getList",
    resource: "warranty",
    payload: {
      sort: {},
      filter: { product_price: price },
    },
  });
  return warranties;
}

export const getSellingPrice = (reference, supplier, type = "new", designation = "") => {
  return dataProvider.getList("sku-selling-price", {
    pagination: { page: 1, perPage: 100 },
    sort: {},
    filter: { reference: reference, supplier: supplier, type: type, designation: designation },
  });
};

export const useSkuSellingPrice = (skuId) => {
  const { data } = useQuery({ type: "getOne", resource: `sku/skus/${skuId}/selling-price`, payload: {} });
  return data ? data.selling_price : null;
};

export const getSkuSellingPrice = (skuId) => {
  return dataProvider
    .getOne(`sku/skus/${skuId}/selling-price`, {})
    .then(({ data }) => (data ? data.selling_price : null));
};

export const createHistorySkuPrice = (skuId, sellingPrice) => {
  const SUPPORT_INPUT = "support_input";
  dataProvider.create("sku-price-history", {
    data: {
      sku: skuId,
      selling_price: sellingPrice * 100,
      strategy: SUPPORT_INPUT,
    },
  });
};

export const createProductModel = (data) => {
  return dataProvider.create("product-models", {
    data: {
      brand: data.brand.id,
      reference: data.reference,
      product_type: data.product_type.id,
    },
  });
};

export const updateProductModelGtin = (data) => {
  return dataProvider.update("product-models", {
    id: data.id,
    data: { gtin: data.gtin || null },
  });
};

export const useLocations = (filter) => {
  const { data: locations } = useQuery({
    type: "getList",
    resource: "location",
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: "id", order: "DESC" },
      filter: { ...filter },
    },
  });
  return locations;
};

export const useSkuLocations = () => {
  const { data: skuLocations } = useQuery({
    type: "getList",
    resource: "sku-location",
    payload: {
      sort: { field: "id", order: "DESC" },
    },
  });
  return skuLocations;
};

export const getSkuModels = async (filter) => {
  const {
    data: skuModels,
    loading,
    error,
  } = await dataProvider.getList("sku-model", {
    pagination: {},
    sort: { field: "id", order: "DESC" },
    filter: filter,
  });
  return { skuModels, loading, error };
};

export const getAswoSparePart = async (aswoReference) => {
  const {
    data: aswoSparePart,
    loading,
    error,
  } = await dataProvider.getOne("murfy-erp/aswo-piece", {
    id: aswoReference,
  });
  return { aswoSparePart, loading, error };
};

export const useAswoArticle = (reference) => {
  const { data: aswoArticle } = useQuery({
    type: "getOne",
    resource: "murfy-erp/aswo-piece",
    payload: { id: reference },
  });
  return aswoArticle;
};

export const useAswoPrice = (reference) => {
  const aswoArticle = useAswoArticle(reference);
  return aswoArticle && aswoArticle.Stueckpreis ? (parseFloat(aswoArticle.Stueckpreis) * 100).toFixed(0) : null;
};

export const useConnectedEmployee = () => {
  const { data: employee } = useQuery({
    type: "getOne",
    resource: "account",
    payload: {},
  });
  return employee;
};

export const getEmployees = async (filter) => {
  const { data: employees } = await dataProvider.getList("employees", {
    sort: {},
    filter: filter,
  });
  return employees;
};

export const useEmployees = () => {
  const { data: employees } = useQuery({
    type: "getList",
    resource: "employees",
    payload: {
      sort: { field: "id", order: "DESC" },
    },
  });
  return employees;
};

export const useWorkshops = () => {
  const { data: workshops } = useQuery({
    type: "getList",
    resource: "workshop",
    payload: {
      sort: { field: "id", order: "DESC" },
    },
  });
  return workshops;
};
export const getQuotes = (customerFileId) => {
  return dataProvider.getManyReference("billing-quotes", {
    target: "customer_file_id",
    id: customerFileId,
    pagination: {},
    sort: { field: "version", order: "DESC" },
  });
};

export const postQuote = (payload) => {
  return dataProvider.create("payment/quotebilling", {
    data: payload,
  });
};

export const sendQuoteSubmissionEmail = (payload) => {
  return dataProvider.create("send-quote-submission-email-v2", {
    data: payload,
  });
};

export const updateQuote = (id, payload) => {
  return dataProvider.update("billing-detail", { id: id, data: payload });
};

export const postPayment = (payload) => {
  return dataProvider.create("payment/paymentbilling", { data: payload });
};

export const createComment = (payload) => {
  return dataProvider.create("comments", {
    data: payload,
  });
};

export const updateComment = (commentId, payload) => {
  return dataProvider.update("comments", {
    id: commentId,
    data: payload,
  });
};

export const updateAttributeValue = (payload) => {
  return dataProvider.create("product/base-attribute-values/update-value", { data: payload });
};

export const createCommentAttachments = (payload) => {
  return dataProvider.create("comment-attachments", {
    data: payload,
  });
};

export const getSkus = (filter) => {
  return dataProvider.getList("skus", { sort: { field: "id", order: "DESC" }, filter }).then(({ data: skus }) =>
    // Sort skus by priority
    sortSkus(skus)
  );
};

export const useCancellationFees = (uuid) => {
  const { data: cancellationFees } = useQuery({
    type: "getList",
    resource: "cancellation-fees",
    payload: {
      sort: { field: "id", order: "DESC" },
      filter: {},
      uuid: uuid,
    },
  });
  return cancellationFees;
};

export const cancellationVisitWithFees = async (uuid, data) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/admin-cancel-with-fees/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(data);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const cancellationVisitWithoutFees = async (uuid) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/admin-cancel-without-fees/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const res = await fetch(url, { headers, method: "POST" });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const needsTeamLeaderCheck = async (uuid, data) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/repair-report-check/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(data);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const reportCheckedByLead = async (uuid, data) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/report-checked-by-lead/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(data);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const useTimeSlots = (employeeId, beginning, end, minimumCapacity = 1, type = "first_intervention") => {
  const { data: timeslots, loading: loadingTimeslots } = useQuery({
    type: "getList",
    resource: "timeslot",
    payload: {
      filter: {
        employee_id: employeeId,
        start_date: beginning,
        end_date: end,
        type: type,
        minimum_capacity: minimumCapacity,
      },
      sort: { field: "id", order: "DESC" },
    },
  });
  return { timeslots, loadingTimeslots };
};

export const useRepairmen = () => {
  const { data: repairmen } = useQuery({
    type: "getList",
    resource: "employees",
    payload: {
      filter: { role: "repairman" },
      sort: { field: "id", order: "DESC" },
    },
  });
  return repairmen;
};

export const proposalTimeslot = async (uuid, data, route = "suggest-timeslot") => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/${route}/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(data);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const getTeamLeader = async (employeeId) => {
  return await dataProvider.getOne(`common/employee/${employeeId}/team-leader`, {});
};

export const useTeamLeader = (employee) => {
  const [teamLeader, setTeamLeader] = useState(null);

  useEffect(() => {
    const fetchData = async (employeeId) => {
      const response = await getTeamLeader(employeeId);
      if (response.status > 200) {
        setTeamLeader(null);
      } else {
        setTeamLeader(response.data);
      }
    };
    if (employee) fetchData(employee.id);
  }, [employee]);

  return teamLeader;
};

export const reschedule = async (uuid, data) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/admin-schedule/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(data);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const useRepairReportChecks = (customerFileId, state) =>
  useQuery({
    type: "getList",
    resource: "repair-report-checks",
    payload: {
      filter: {
        customer_file: customerFileId,
        state,
      },
      sort: { field: "id", order: "ASC" },
    },
  });

export const postComment = (payload) => {
  return dataProvider.create("comments", {
    data: payload,
  });
};

export const updateRepairReportCheck = (repairReportCheckId, payload) => {
  return dataProvider.update("repair-report-checks", { id: repairReportCheckId, data: payload });
};

export const convertToCustomerFile = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/convert/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(payload);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const settleCreditNotes = async ({ invoiceItems, commercialGestureAmount, creditNoteId, notifyCustomer }) => {
  const url = `${process.env.REACT_APP_API_URL}/payment/settlement/`;

  const token = localStorage.getItem("token");
  const headers = getHeaders(token);

  const response = await fetch(url, {
    headers: headers,
    method: "POST",
    body: JSON.stringify({
      credit_notes: [creditNoteId],
      itemized_credit_note: { items: invoiceItems, credit_note: creditNoteId },
      commercial_gesture_amount: commercialGestureAmount,
      notify_customer: notifyCustomer,
    }),
  });

  try {
    let data = await response.json();

    // Temporary conversion to handle new endpoint version
    data = data.coupon ? data.coupon : data;
    return { data: data, status: response.status };
  } catch {
    return { status: response.status };
  }
};

export const useLastVisitPreparation = (customerFileId) => {
  const { data: lastVisitPreparation } = useQuery({
    type: "getOne",
    resource: `murfy-erp/customer-files/${customerFileId}/last-visit-preparation`,
    payload: {},
  });
  return lastVisitPreparation;
};

export const updateVisitPreparation = (taskId, payload) => {
  return dataProvider.update("visit-preparations", { id: taskId, data: payload });
};
export const reopeningCustomerFile = async (uuid) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/admin-reopening/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const res = await fetch(url, { headers, method: "POST" });
  try {
    const data = await res.json();
    return { data: data.data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const useHomeRepairZoneDeployedOnSevenOpteam = (postal_code) => {
  const { data: deployementStatus } = useQuery({
    type: "getOne",
    resource: "seven-opteam-deployed",
    payload: {
      postal_code: postal_code,
    },
  });
  return deployementStatus;
};

export const bookAppointment = async (uuid, payload) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/customer-file/${uuid}/book-appointment/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const res = await fetch(url, { headers, method: "POST", body: JSON.stringify(payload) });
  try {
    const data = await res.json();
    return { data: data, status: res.status };
  } catch {
    return { status: res.status };
  }
};

export const postIncentive = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}/murfy-erp/incentives/`;
  const token = localStorage.getItem("token");

  const headers = getHeaders(token);
  const encodedData = JSON.stringify(payload);
  const res = await fetch(url, { headers, method: "POST", body: encodedData });
  try {
    const data = await res.json();
    return { data: data, status: res.status };
  } catch {
    return { status: res.status };
  }
};
